import React, { useState, useEffect } from "react";
import ChangePasswordModal from "./ChangePassword/";
import { refreshUserDetails } from "../../../reducers/client/actions";
import { updateWeeklyAlert,getMyAccount } from "../../../service/client";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { timestampToDate, renderCount } from "../../../utils";

// Account Information component
function AccountInfo(props) {
  const dispatch = useDispatch();
  const myaccount = useSelector((store) => store.client.myaccount);
  const [alert, setAlert] = useState(0);
  useEffect(()=>{
    if (window.analytics) {
      window.analytics.page();
    }
    setAlert(parseInt(myaccount.User_OptInAlerts));
  },[myaccount]);

  
  const [changePasswordModal, setchangePasswordModal] = useState(false);
 
const info = useSelector((store) => store.client.info);
const plan = useSelector((store) => store.client.plan);
  const toggleChangePassword = () =>
    setchangePasswordModal(!changePasswordModal);

  // function to call weekly alert  mail ON / OFF api
  const handleAlert = e => {
    let param = { weeklyAlert: e.target.checked ? 1 : 0 };
    setAlert(e.target.checked ? 1 : 0);
    updateWeeklyAlert(param).then(res =>
      handleResponse(res),
    );
  };

  const handleResponse = (res) => {
    getMyAccount().then(res =>
      dispatch(refreshUserDetails(res.data.data))
    );
  }

  return (
    <>
      <div className="row">
        <div className="col acc-subp">
          <h2 className="display-5 text-uppercase mt-5 account-test ">
            Subscription
          </h2>
          <div className="inner background-grey p-2 f08em add-subscribe acc-subp-left">
            <div className="row">
              <div className="col-6">
                <p className="curr-sub">
                  <strong>Current Subscription </strong>
                </p>

                <p className="lead">{plan.Sub_Name}</p>
                <p className="curr-sub">
                  <strong>Current Subscription Period</strong>
                </p>
                <p className="text-small mb-0 subsp-credit">
                  <strong>Started: </strong>
                  {timestampToDate(myaccount.DateTime_SubscriptionStart)}
                  <br />
                  <strong>Expires: </strong>
                  {timestampToDate(myaccount.DateTime_SubscriptionEnd)}
                </p>
              </div>
              <div className="col-6">
                <p className="credit-avbl">
                  <strong>Credits</strong>
                </p>
                <p className="lead">
                  <strong>Current Credit Balance: </strong>
                  {myaccount.User_Subscription === "10"
                    ? "Unlimited"
                    : renderCount(myaccount.User_DL_Reserves)}{" "}
                  <br />
                  {plan.Sub_Level !== "10" ? (
                    <>
                      <strong>Cost per additional credit: </strong>
                      {(plan.Sub_CostPerDL * 100).toFixed(0)} cents
                    </>
                  ) : (
                    ""
                  )}
                </p>

                {plan.Sub_Level !== "10" && info.isParent === true ? (
                  <p className="text-small mb-0">
                    <Link to="/upgrade-subscription">Upgrade Subscription</Link>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col acc-subp">
          <h2 className="display-5 text-uppercase mt-5 account-test">
            Account Information
          </h2>
          <div className="inner background-grey p-2 f08em add-subscribe acc-subrcp">
            <div className="row">
              <div className="col">
                <p className="curr-sub">
                  <strong>Primary User</strong>
                </p>
                <p className="lead">
                  {info.isParent ?
                    myaccount.User_FirstName + "  " + myaccount.User_LastName
                    :
                    myaccount.parent.User_FirstName + "  " + myaccount.parent.User_LastName
                  }
                  <br />
                  <Link
                    onClick={toggleChangePassword}
                    className="change-password"
                  >
                    Change Password
                  </Link>
                </p>
                <p className="text-small mb-0">{myaccount.User_Email}</p>
                
                <br />
              </div>
              <div className="col">
                <p className="credit-avbl">
                  <strong>Email Alert Settings</strong>
                </p>
                <div className="custom-control custom-checkbox">
                  <label className="switch1">
                    Receive Weekly Alerts Regarding Available Record Updates
                    <input
                      onChange={handleAlert}
                      type="checkbox"
                      id="userOptinAlerts"
                      checked={alert}
                    />
                    <div className="slider round">
                      <span className="on">ON</span>
                      <span className="off">OFF</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { changePasswordModal === true ?
        <ChangePasswordModal
          modal={changePasswordModal}
          toggle={toggleChangePassword}
        />
        :
        ""
      }
    </>
  );
}

export default AccountInfo;
