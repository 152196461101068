import React, { useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { loadSavedSearchNameArrayType, getAppliedGovSpendCategory } from '../../../../utils';
import { setAppliedFilters,setValidateFilters } from '../../../../reducers/client/actions';
// government spend filter component
function GovernmentSpend(props) {

  let [expanded, setExpanded] = React.useState('');
  let dispatch = useDispatch();
  let [category, setCategory] = React.useState('');
  let [subcategory, setSubcategory] = React.useState({});
  //categorie of spend filter
  const categories = ["finPS", "finPW", "finH", "finU", "finT", "finL", "finF", "finM"];
  let rolesFilter = useSelector(store => store.client.validFilters);
  // government spend filter and values
  let formFields = [
    { checked: true, label: "Highest Spenders (4th Quartile)", name: "expenseBudgetQuartileSel[]", value: "4" },
    { checked: true, label: "High-Medium (3rd Quartile)", name: "expenseBudgetQuartileSel[]", value: "3" },
    { checked: true, label: "Low-Medium (2nd Quartile)", name: "expenseBudgetQuartileSel[]", value: "2" },
    { checked: true, label: "Lowest Spenders (1st Quartile)", name: "expenseBudgetQuartileSel[]", value: "1" },
    { checked: true, label: "Spend $0 or data not avlb", name: "expenseBudgetQuartileSel[]", value: "5" }
  ];


  const [CheckedCount, setCheckedCount] = React.useState(formFields.length);

  const [Fields, setCheckedFields] = React.useState(formFields)

  let [PreviousParams, setPreviousParams] = React.useState({});
  // set initial checked inputs or loaded saved search parameters
  useEffect(() => {

    if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {
      let loadParams = "";
      let loadSavedSearch = [];
      let appliedCategories = {};
      setCategory('');
      setSubcategory('');
      if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
        loadParams = props.currentSearch.search_params;
        loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
        appliedCategories = getAppliedGovSpendCategory(loadParams, categories);
      } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
        loadParams = props.currentSearch.params;
        loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
        appliedCategories = getAppliedGovSpendCategory(loadParams, categories);
      }
      if (Fields !== loadSavedSearch && appliedCategories.length !== 0) {
        if (appliedCategories.category === "expType" && appliedCategories.subcategory === "1") {
          setCategory(appliedCategories.subcategory);
          setSubcategory(appliedCategories.subcategory);
        } else {
          setCategory(appliedCategories.category);
          setSubcategory(appliedCategories.subcategory);
        }

        setExpanded("govspend");
      }

      if (Fields !== loadSavedSearch && loadSavedSearch.length !== 0) {
        setPreviousParams(props.currentSearch);
        setCheckedFields(loadSavedSearch.appliedFilters);
        setCheckedCount(loadSavedSearch.checkedcount);
        let appliedFilters = props.appliedFilters;
        appliedFilters.governmentSpend = loadSavedSearch.checkedcount;
        dispatch(setAppliedFilters(appliedFilters));
        if (loadSavedSearch.checkedcount !== 0 && loadSavedSearch.checkedcount !== formFields.length) {
          setExpanded("govspend");
        } else {
          if (expanded !== "") {

          } else {
            setExpanded("");
          }
        }

      }
    }
  });
  // funcation to capture filter values and trigger search api
  const handleChange = (event) => {
    setSubcategory(event.target.value);
    let allSearchFilterTT = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: true,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
    props.triggerSearch('','',allSearchFilterTT);
  };
  // function to capture changed quartile values
  const handleQuartileChange = (event) => {
    let MinimumChecked = 0;
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      if (event.target.value === field.value) {
        field.checked = event.target.checked;
      }
      if (field.checked === true) {
        MinimumChecked++;
      }
      uncheckedInputs.push(field);
    })
    let  allSearchFilter = {};
    if(MinimumChecked===0)
    {
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: false,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    } else {
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: true,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    }
    setCheckedFields(uncheckedInputs);
    setCheckedCount(MinimumChecked);
    let appliedFilters = props.appliedFilters;
    appliedFilters.governmentSpend = MinimumChecked;
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true, allSearchFilter);
    
  };

  // function to handle parent categories
  const handleParentCategoryChange = (event) => {
    setCategory(event.target.value);
    let allSearchFilterTT = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: true,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
    props.triggerSearch('', false,allSearchFilterTT);
  };
  // function to capute spend category and trigger search api
  const handleSpendCategory = (event) => {
    let allSearchFilterTT = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: true,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
    setSubcategory(event.target.value);
    props.triggerSearch('', false, allSearchFilterTT);
  }
  // function set opened or closed state of filter
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (

    <div id="governmentSpend" className="row">
      <div className="col ">
        <Accordion className="accordianLine" expanded={expanded === 'govspend'} onChange={handleChangeAccordian('govspend')}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon />}
            aria-controls="govspenda-content"
            id="govspenda-header"
          >
            <Typography className=""> Government Spend</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {CheckedCount === 0 ?
                <div className="text-danger text-center mb-2 text-small w-100" >Select 1 or more options</div>
                :
                ""
              }
              <input type="hidden" name="criteria" value="expenseBudgetQuartile" />
              {(category === "1" || category === "") ? <input type="hidden" name="expType" value="1" /> : ""}


              {
                Fields.map((field, index) => {
                  return (
                    <div key={index} className="col-12">
                      <FormGroup>
                        <FormControlLabel
                          className="form-check-label  roleLabel"
                          control={<Checkbox checked={field.checked} className="roleCheckbox" onChange={handleQuartileChange} name={field.name} value={field.value} />}
                          label={<Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                        />
                      </FormGroup>
                    </div>
                  );

                })
              }

              <div className="col-12">
                <div className="form-group form-inline">
                  <label htmlFor="spendRangeCategory" className="text-small ">On</label>

                  <select value={category} onChange={handleParentCategoryChange} id="spendRangeCategory" className="form-control col-10 ml-3">
                    <option value="1" >Total Expenditure</option>
                    <option value="finPS" >Public Safety</option>
                    <option value="finPW" >Public Welfare</option>
                    <option value="finH" >Health</option>
                    <option value="finU" >Utilities</option>
                    <option value="finT" >Transportation</option>
                    <option value="finL" >Leisure</option>
                    <option value="finF" >Finance</option>
                    <option value="finM" >Miscellaneous</option>
                    <option value="expType" >Special Categories</option>
                  </select>

                </div>
                <div className="form-group" id="spendRangeSubCategories">

                  {category === "finPS" ?
                    <select value={subcategory} onChange={handleChange} name="finPS" id="finPS" className="form-control">
                      <option value="All">Total Public Safety</option>
                      <option value="Corrections">Corrections</option>
                      <option value="Fire+Protection">Fire Protection</option>
                      <option value="Judicial+and+Legal">Judicial and Legal</option>
                      <option value="Police">Police</option>
                    </select>
                    :
                    ""
                  }

                  {category === "finPW" ?

                    <select value={subcategory} onChange={handleChange} name="finPW" id="finPW" className=" form-control">
                      <option value="All">Total Public Welfare</option>
                      <option value="Assistance+and+Subsidies">Assistance and Subsidies</option>
                      <option value="Welfare+Program+Administration">Welfare Program Administration</option>
                    </select>
                    :
                    ""
                  }

                  {category == "finH" ?

                    <select value={subcategory} onChange={handleChange} name="finH" id="finH" className=" form-control">
                      <option value="All">Total Health</option>
                      <option value="Health+Services">Health Services</option>
                      <option value="Hospitals">Hospitals</option>
                    </select>
                    :
                    ""
                  }

                  {category === "finU" ?
                    <select value={subcategory} onChange={handleChange} name="finU" id="finU" className=" form-control">
                      <option value="All">Total Utilities</option>
                      <option value="Electricity">Electricity</option>
                      <option value="Gas">Gas</option>
                      <option value="Sewerage">Sewerage</option>
                      <option value="Solid+Waste+Management">Solid Waste Management</option>
                      <option value="Water">Water</option>
                    </select>
                    :
                    ""
                  }

                  {category === "finT" ?
                    <select value={subcategory} onChange={handleChange} name="finT" id="finT" className=" form-control">
                      <option value="All">Total Transportation</option>
                      <option value="Airports">Airports</option>
                      <option value="Parking">Parking</option>
                      <option value="Ports+and+Waterways">Ports and Waterways</option>
                      <option value="Public+Transit">Public Transit</option>
                      <option value="Roads+and+Highways">Roads and Highways</option>
                    </select>
                    :
                    ""
                  }

                  {category === "finL" ?
                    <select value={subcategory} onChange={handleChange} name="finL" id="finL" className=" form-control">
                      <option value="All">Total Leisure</option>
                      <option value="Libraries">Libraries</option>
                      <option value="Parks+and+Recreation">Parks and Recreation</option>
                    </select>
                    :
                    ""
                  }

                  {category === "finF" ?
                    <select value={subcategory} onChange={handleChange} name="finF" id="finF" className=" form-control">
                      <option value="All">Total Finance</option>
                      <option value="Financial+Administration">Financial Administration</option>
                      <option value="Interest+on+Debt">Interest on Debt</option>
                    </select>
                    :
                    ""
                  }

                  {category === "finM" ?
                    <select value={subcategory} onChange={handleChange} name="finM" id="finM" className=" form-control">
                      <option value="Education">Education</option>
                      <option value="General+Administration">General Administration</option>
                      <option value="Housing+and+Community+Development">Housing &amp; Community Development</option>
                      <option value="Liquor+Stores">Liquor Stores</option>
                      <option value="Natural+Resources">Natural Resources</option>
                    </select>

                    :
                    ""
                  }

                  {category === "expType" ?
                    <select value={subcategory} onChange={handleSpendCategory} id="expType" name="expType" className=" form-control">
                      <option value="2">Only Capital Expenditures</option>
                      <option value="3">Only Operating Expenditures</option>
                      <option value="4">Only Salaries &amp; Wages</option>
                      <option value="5">Only Expenditures on Suppliers</option>
                    </select>
                    :
                    ""
                  }


                </div>
              </div>

            </div>
          </AccordionDetails>
        </Accordion>

      </div>
    </div >

  )
}


function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
  }
}

export default connect(mapStateToProps, {})(GovernmentSpend);