import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import RefinedFilter from './Filters/refinedFilter';
import RefinedEmailFilter from './Filters/refineEmailFilter';

import NewOfficials from './Filters/newOfficials';
import GovernmentTypes from './Filters/governmentType';
import Population from './Filters/population';
import GovernemntRoles from './Filters/governmentRoles';
import Locations from './Filters/LocationByState';
import GovernmentSpend from './Filters/governmentSpend';
import GovernmentFTE from './Filters/governmentFTEs';
import FiscalYearEnd from './Filters/fiscalYearEnding';
import MonthOfElection from './Filters/monthOfElections';
import { setAppliedFilters, setSearchResult, setCurrentSearch, setBestPricing, setSearchLoading, setCheckout } from '../../../reducers/client/actions';
import { getSearchResult, getbestPricing } from '../../../service/client';
import $ from "jquery";

import { validateSearchFilters } from '../../../utils';
import Swal from 'sweetalert2';
// search filter component
function SearchFilter(props) {
  const dispatch = useDispatch();
  let processing = false;
  let [lastSearch, setLastSearch] = useState([]);
  let [filterlistval, setfilterlistvalue] = useState(false);
  let tamMode = useSelector(store => store.client.tamMode);
  const newSearch = props.newSearch;
  let rolesFilterData = useSelector(store => store.client.validFilters);
  
  
  // important constractor to load new / existing saved searh 
  useEffect(() => {
    
    if (Object.keys(props.currentSearch).length === 0 && props.searchLoading === false && props.searchResult.length === 0 && props.info.showUserSurvey === false && props.info.newUserFirstLogin === false) {
      //if(lastSearch!==new_search_params){
      dispatch(setCurrentSearch(newSearch));
      dispatch(setCheckout([]));
      setLastSearch(newSearch);
      processSearch(newSearch);
      //}

    } else if (Object.keys(props.currentSearch).length !== 0 && props.searchLoading === false && props.searchResult.length === 0 && props.info.showUserSurvey === false && props.info.newUserFirstLogin === false) {
      let loadSearch = {
        search_params: props.currentSearch.search_params,
        id: props.currentSearch.id,
        name: props.currentSearch.name,
        source: props.currentSearch.type,
        first_time_login: 0
      }

      if (lastSearch !== loadSearch) {
        setLastSearch(lastSearch);
        processSearch(loadSearch);
        dispatch(setCheckout([]));
      }
    }

  });

  // function to trigger search api call if any filter has changed
  const handleFormChange = (fields, validate,valueFilter) => {
    if (processing) {
      clearTimeout(processing);
    }
    processing = setTimeout(function () {
      validateFormAndSearch(fields, validate,valueFilter)
    }, 2000);
  }

  // function to validate applied filter criteria is valid to perform search
  const validateFormAndSearch = (fields, validate,valueFilter) => {
    if (validate === true) {
      let MinimumChecked = 0;
      fields.map((field, index) => {
        if (field.checked === true) {
          MinimumChecked++;
        }
      });
      if (MinimumChecked === 0 && validate == true) {
        return false;
      }
    }
    if(valueFilter.rolesf==true && valueFilter.statesf==true && valueFilter.gtypef==true && valueFilter.gftef==true && valueFilter.gspendf==true && valueFilter.fydf==true && valueFilter.molf==true)
    {
      processSearch('');
    }
  }
  // ready to perform search
  const processSearch = (defaultSearch) => {
    if (props.searchLoading === true) {
      return false;
    }
    var data = decodeURIComponent($('#SearchForm').serialize());
    data = data.replace('&byZipcode=&byZipCodeDistance=&', '&');
    let allSearch = {};
    if (tamMode === true && defaultSearch !== "") {
      let defaultparams = defaultSearch.search_params;
      defaultparams = defaultparams.replace('&populationMoreThan=0&populationThan=20000000&', '&populationMoreThan=1000&populationThan=20000000&');
      allSearch = {
        search_params: defaultparams,
        id: "0",
        source: "",
        name: "All Records",
        first_time_login: "0"
      }

    } else {
      allSearch = defaultSearch;
    }


    let userSearch = {
      search_params: data,
      id: "",
      source: "",
      name: "",
      first_time_login: "0"
    }
    userSearch = defaultSearch === "" ? userSearch : allSearch;
    // console.log("userSearch",userSearch)
    /*if (props.plan.Sub_Level !== "10") {
      userSearch.search_params = userSearch.search_params.replace("&role_22=HeadofCorrections", '&');
    }*/
    dispatch(setCurrentSearch(userSearch));

    let searchIsValid = validateSearchFilters(props.appliedFilters);
    if (searchIsValid.proceed === false) {

      let appliedFilters = props.appliedFilters;
      appliedFilters.IsValidSeach = false;
      dispatch(setAppliedFilters(appliedFilters));
      return false;
    } else {
      let appliedFilters = props.appliedFilters;
      appliedFilters.IsValidSeach = true;
      dispatch(setAppliedFilters(appliedFilters));
    }
    
    dispatch(setSearchLoading(true));
    getSearchResult(userSearch).then(res => handleSearchResponse(res, userSearch));
    
  }
  // function to handle search result
  const handleSearchResponse = (response, userSearch) => {
    if (response.data.code === 200) {
      dispatch(setSearchResult(response.data.data));

      let unpaid = 0;
      if (props.plan.Sub_Level !== "10") {
        unpaid = parseInt(response.data.data.lastSearchNumMatched);
      }

      let bestParams = {
        checkout_paid_records: response.data.data.checkout_paid_records,
        checkout_unpaid_records: unpaid,
      }
      if (parseInt(response.data.data.scrvr) !== 0) {
        getbestPricing(bestParams).then(response =>
          dispatch(setBestPricing(response.data.data)),
        )
      } else {
        dispatch(setBestPricing([]));
      }
      dispatch(setSearchLoading(false));

    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        text: "",
        title: "Oops! Something went to wrong...",
        cancelButtonText: 'Close'
      });

    }
  }
  let disableFilter = props.searchLoading ? "disableFilter" : "";

  return (
    <>
      <div className="row">
        <div className="col-12 text-center mt-3">
          <h2>Your Search FILTERS</h2>
        </div>
      </div>
      <div id="filter-section" className={"row " + disableFilter}>
        <div className="col">
          <form id="SearchForm">
            <input type="hidden" onChange={handleFormChange} id="byText" name="byText" value="" />
            <input type="hidden" name="off_role" value="govtOff_role" />
            <input type="hidden" name="tamMode" value={tamMode} />
            <RefinedEmailFilter triggerSearch={handleFormChange} />
            <RefinedFilter triggerSearch={handleFormChange} />
            <NewOfficials triggerSearch={handleFormChange} />
            <GovernemntRoles triggerSearch={handleFormChange} />
            <Population filterTrigger={rolesFilterData} triggerSearch={handleFormChange} />
            <Locations triggerSearch={handleFormChange} />
            <GovernmentTypes triggerSearch={handleFormChange} />
            <GovernmentFTE triggerSearch={handleFormChange} />
            <GovernmentSpend triggerSearch={handleFormChange} />
            <FiscalYearEnd triggerSearch={handleFormChange} />
            <MonthOfElection triggerSearch={handleFormChange} />

          </form>
        </div>
      </div>
    </>
  )
}
//set  initial props to component
function mapStateToProps(store, props) {
  return {
    info: store.client.info ? store.client.info : [],
    plan: store.client.info ? store.client.plan : [],
    myaccount: store.client.myaccount ? store.client.myaccount : [],
    searchResult: store.client.searchResult ? store.client.searchResult : [],
    searchLoading: store.client.searchLoading,
    newSearch: store.client.newSearch,
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
  }
}

export default connect(mapStateToProps, {})(SearchFilter);