import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { loadSavedSearchNameArrayType } from '../../../../utils';
import { setAppliedFilters,setValidateFilters } from '../../../../reducers/client/actions';

// Month of eleection filter
function MonthOfElection(props) {
    const [expanded, setExpanded] = React.useState();
    const dispatch = useDispatch();
    const handleChangeAccordian = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    let rolesFilter = useSelector(store => store.client.validFilters);
    // initial month of election filter and values
    const formFields = [
        { checked: true, name: "electionMonth[]", value: "1", label: "January" },
        { checked: true, name: "electionMonth[]", value: "7", label: "July" },
        { checked: true, name: "electionMonth[]", value: "2", label: "February" },
        { checked: true, name: "electionMonth[]", value: "8", label: "August" },
        { checked: true, name: "electionMonth[]", value: "3", label: "March" },
        { checked: true, name: "electionMonth[]", value: "9", label: "September" },
        { checked: true, name: "electionMonth[]", value: "4", label: "April" },
        { checked: true, name: "electionMonth[]", value: "10", label: "October" },
        { checked: true, name: "electionMonth[]", value: "5", label: "May" },
        { checked: true, name: "electionMonth[]", value: "11", label: "November" },
        { checked: true, name: "electionMonth[]", value: "6", label: "June" },
        { checked: true, name: "electionMonth[]", value: "12", label: "December" },
        { checked: true, name: "electionMonth[]", value: "13", label: "Not Avlb" }
    ];
    const [Fields, setCheckedFields] = React.useState(formFields)
    const [CheckedCount, setCheckedCount] = React.useState(formFields.length);
    let [PreviousParams, setPreviousParams] = React.useState({});
    // set initial filter or load saved search paramenter
    useEffect(() => {
        if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {

            let loadParams = "";
            let loadSavedSearch = [];
            if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
                loadParams = props.currentSearch.search_params;
                loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
            } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
                loadParams = props.currentSearch.params;
                loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
            }

            if (Fields !== loadSavedSearch && loadSavedSearch.length !== 0) {

                setPreviousParams(props.currentSearch);
                setCheckedFields(loadSavedSearch.appliedFilters);
                setCheckedCount(loadSavedSearch.checkedcount);
                let appliedFilters = props.appliedFilters;
                appliedFilters.monthOfEelections = loadSavedSearch.checkedcount;
                dispatch(setAppliedFilters(appliedFilters));
                if (loadSavedSearch.checkedcount !== 0 && loadSavedSearch.checkedcount !== formFields.length && loadSavedSearch.checkedcount < 12) {
                    setExpanded("electionMonth");
                } else {
                    if (expanded !== "") {

                    } else {
                        setExpanded("");
                    }
                }

            }
        }
    });

    // function to capture election month filter values and trigger search api
    const handleChange = (event) => {
        let MinimumChecked = 0;
        let uncheckedInputs = [];
        Fields.map((field, index) => {
            if (event.target.value === field.value) {
                field.checked = event.target.checked;
            }
            if (field.checked === true) {
                MinimumChecked++;
            }
            uncheckedInputs.push(field);
        });
        setCheckedFields(uncheckedInputs);
        setCheckedCount(MinimumChecked);
        let appliedFilters = props.appliedFilters;
        appliedFilters.monthOfEelections = MinimumChecked;
        dispatch(setAppliedFilters(appliedFilters));
        let  allSearchFilter = {};
        if(MinimumChecked===0)
        {
          
          allSearchFilter = {
            rolesf: rolesFilter.rolesf,
            statesf: rolesFilter.statesf,
            gtypef: rolesFilter.gtypef,
            gftef: rolesFilter.gftef,
            gspendf: rolesFilter.gspendf,
            fydf: rolesFilter.fydf,
            molf: false
          }
          dispatch(setValidateFilters(allSearchFilter));
        } else {
          allSearchFilter = {
            rolesf: rolesFilter.rolesf,
            statesf: rolesFilter.statesf,
            gtypef: rolesFilter.gtypef,
            gftef: rolesFilter.gftef,
            gspendf: rolesFilter.gspendf,
            fydf: rolesFilter.fydf,
            molf: true
          }
          dispatch(setValidateFilters(allSearchFilter));
        }
        props.triggerSearch(uncheckedInputs, true, allSearchFilter);
    };
    // function to select all election month checkboxes
    const handleSelectAll = () => {
        let uncheckedInputs = [];
        Fields.map((field, index) => {
            field.checked = true;
            uncheckedInputs.push(field);
        })
        let  allSearchFilterdt = {
            rolesf: rolesFilter.rolesf,
            statesf: rolesFilter.statesf,
            gtypef: rolesFilter.gtypef,
            gftef: rolesFilter.gftef,
            gspendf: rolesFilter.gspendf,
            fydf: rolesFilter.fydf,
            molf: true
        }
        dispatch(setValidateFilters(allSearchFilterdt));
        setCheckedFields(uncheckedInputs);
        setCheckedCount(Fields.length);
        let appliedFilters = props.appliedFilters;
        appliedFilters.monthOfEelections = 13;
        dispatch(setAppliedFilters(appliedFilters));
        props.triggerSearch(uncheckedInputs, true, allSearchFilterdt);
        
    };
    // function to deselect all election month checkboxes
    const handleDeSelectAll = () => {
        let uncheckedInputs = [];
        Fields.map((field, index) => {
            field.checked = false;
            uncheckedInputs.push(field);
        })
        setCheckedFields(uncheckedInputs);
        setCheckedCount(0);
        let appliedFilters = props.appliedFilters;
        appliedFilters.monthOfEelections = 0;
        dispatch(setAppliedFilters(appliedFilters));
        let  allSearchFilterdf = {
            rolesf: rolesFilter.rolesf,
            statesf: rolesFilter.statesf,
            gtypef: rolesFilter.gtypef,
            gftef: rolesFilter.gftef,
            gspendf: rolesFilter.gspendf,
            fydf: rolesFilter.fydf,
            molf: false
        }
        dispatch(setValidateFilters(allSearchFilterdf));

    };
    let previousParams = "";
    if (Object.keys(props.currentSearch).length !== 0) {
        if (props.currentSearch.id !== "0" && props.currentSearch.id !== "") {
            previousParams = props.currentSearch.search_params;
        } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
            previousParams = props.currentSearch.params;
        }
    }

    return (

        <div id="monthOfElection" className="row governmentRoles">
            <div className="col">
                <Accordion expanded={expanded === 'electionMonth'} onChange={handleChangeAccordian('electionMonth')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="electionMontha-content"
                        id="electionMontha-header"
                    >
                        <Typography className="">Month of Elections</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">

                            {
                                Fields.map((field, index) => {
                                    return (
                                        <div key={index} className={"col-6 pr-0 month" + index}>
                                            <FormGroup >
                                                <FormControlLabel
                                                    className="form-check-label roleLabel text-small"
                                                    control={<Checkbox checked={field.checked} onChange={handleChange} name={field.name} value={field.value} />}
                                                    label={field.label}
                                                />
                                            </FormGroup>
                                        </div>
                                    );
                                })
                            }
                            <div className="col-12 text-small text-center">
                                {CheckedCount === 0 ?
                                    <div className="text-danger mb-2 w-100" >Select 1 or more months</div>
                                    :
                                    ""
                                }
                                <a onClick={handleSelectAll} href="javascript:void(0)" className="select-all" >Select All</a> | {" "}
                                <a onClick={handleDeSelectAll} href="javascript:void(0)" className="deselect-all" >Deselect All</a>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>
        </div >

    )
}

function mapStateToProps(store, props) {
    return {
        currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
        appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : []
    }
}

export default connect(mapStateToProps, {})(MonthOfElection);
